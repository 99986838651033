import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ContactForm from "../components/ContactForm";
import TrustedBy from "../components/ai-yacht-boat-chatbot/TrustedBy"
import * as Product from "../const/products"
import {
  Accent,
  Accent2,
  Sentence,
  Together,
} from "../components/StyledComponents";
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image";
import RequestDemo from "../components/Common/RequestDemoModal";
import { Link } from "gatsby";
import {graphql, useStaticQuery} from "gatsby"
import BlogPostHeroImage from "../components/homepage/BlogPostHeroImage";

const data = {
  canonical: "/",
  title: "Yachting.AI - AI for Yachting Industry",
};

const IndexPage = () => {
  const [product, setProduct] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const {blog} = useStaticQuery(graphql`
      query HomePageBlogPageQuery {
          blog: allMdx(sort: {fields: frontmatter___date, order: DESC}, limit: 3) {
              posts: nodes {
                  slug
                  id
                  hero: heroImage {
                      childImageSharp {
                          gatsbyImageData
                      }
                  }
                  meta: frontmatter {
                      title
                      herourl
                      date(formatString: "MMM D, YYYY")
                  }
                  excerpt
                  tableOfContents
              }
          }
      }
  `)
  return (
    <Layout
      breadcrumbs={true}
      breadcrumbsJustMeta={true}
      canonical={data.canonical}
      title={data.title}
    >
      <Seo
        title={"AI For Yachting"}
        lang={"en-US"}
        description={"AI Services and Products for Yachting Industry to Boost Leads and Elevate Client Satisfaction"}
        canonical={"https://Yachting.ai/"}
      />

      <section
        className={"relative hero bg-black md:h-screen flex items-end justify-center"}
      >
        <div className="absolute inset-0 mt-16">
          <StaticImage
            src={"../images/yachting-ai-hero-image-4.png"}
            alt={"AI Services for Yachting Industry"}
            className="w-full md:h-75p scale-150 md:scale-100"
          />
        </div>
        <div className="z-10 w-full sm:mt-80 mt-44 md:mt-0">
          <h1
            className={"homepage text-var-12xl mt-20 uppercase text-center leading-[1] pb-5"}
          >
            {/* Navigate To <br /> Success */}
            {/* Capture <br/>The Lead */}
            AI Designed<br /> for Yachting
          </h1>
          <p
            className={"text-off-white text-var-2xl text-center pb-10 px-6"}
          >
            Navigate the future of yacht sales with our AI solutions. Boost
            leads and elevate client satisfaction.
          </p>
          <div
            className={"flex flex-col md:flex-row relative justify-center w-full pb-16 lg:pb:28"}
                >
                    {/* <div */}
                    {/*     className={"flex order-2 md:order-1 flex-col justify-center "} */}
                    {/* > */}
                    {/*     <h2 className={"text-3xl text-center text-off-black font-bold leading-none leading-tight"}> */}
                    {/*         Trusted by Leading Yachting Companies */}
                    {/*     </h2> */}
                    {/*     <div className="flex justify-center items-center space-x-12 md:pt-10 p-5"> */}
                    {/*         <StaticImage */}
                    {/*             src={"../images/yachting-ai-our-partner-denison-yachting-white.png"} */}
                    {/*             placeholder={"blurred"} */}
                    {/*             alt={"Denison Yachting is using out AI Yacht Chat"} */}
                    {/*             className="mx-25 w-72" */}
                    {/*             objectFit={"contain"} */}
                    {/*         /> */}
                    {/**/}
                    {/*         <StaticImage */}
                    {/*             src={"../images/yachting-ai-our-partner-yatco-white.png"} */}
                    {/*             placeholder={"blurred"} */}
                    {/*             alt={"Denison Yachting is using out AI Yacht Chat"} */}
                    {/*             className="mx-25 w-72" */}
                    {/*             objectFit={"contain"} */}
                    {/*         /> */}
                    {/**/}
                    {/*     </div> */}
                    {/* </div> */}

                </div>
        </div>
      </section>

      {/*<ScreenSizeHelper />*/}
      <section className={"px-8 py-8 md:py-16 max-container"}>
        <div
          className={"flex flex-col md:flex-row relative justify-center w-full"}
        >
          <div
            className={"flex order-2 md:order-1 flex-col justify-center md:w-1/2"}
          >

            <Link
              to={"/ai-yacht-boat-chatbot"}
              title={"AI Yacht Chat"}
              className="hover:translate-x-10 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300"
            >
              <h2
                className={"text-var-6xl block mb-2 md:mb-6 font-bold lg:w-full"}
              >
                AI Yacht Chat
              </h2>
            </Link>

            <p
              className={"text-var-2xl col-start-1 row-start-2 lg:w-full md:pr-12"}
            >
              <Sentence>
                Prepared and developed with particular care for the{" "}
                <b>yachting industry</b>, the AI ChatBot can accurately respond
                to customers' very specific questions, and integration with MLS
                allows for live display of yacht search results.
              </Sentence>

              <Link
                to={"/ai-yacht-boat-chatbot"}
                title={"AI Yacht Chat"}
                className="group flex text-xl mt-5 text-pink-900 hover:text-pink-600 transition ease-in-out duration-300"
              >
                <svg className="w-4 h-4 mt-2 mr-2 group-hover:mr-0 group-hover:ml-2 transition-all duration-300 ease-in-out" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                </svg>
                <span className="border-b border-pink-900">Learn More About <b>AI Yacht Chat </b></span>
              </Link>
            </p>


            <div className="flex flex-col md:flex-row space-x-4 md:items-end items-center mt-4 md:mt-8">
              <button
                className={"md:mr-10 cursor-pointer relative text-center gradient-bg-purple hover:gradient-bg-fire text-white hover:text-bold uppercase font-bold text-xl rounded-full  ml-0 py-4 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                // href="#contact-form-a"
                onClick={() => {
                  setProduct(Product.AI_CHATBOT);
                  setShowModal(true);
                }}
              >
                Get More Info
              </button>

            </div>
          </div>
          <div
            className={"order-1 sm:px-32 md:px-0 text-center md:w-1/2 lg:w-1/3 md:ml-12 mb-8 md:mb-0 md:mt-0"}
          >
            <StaticImage
              src={"../images/yachting-ai-chatbot.png"}
              alt={"Yachting.AI Tailored AI ChatBot"}
              className="hover:-translate-y-1 md:w-auto w-44 hover:scale-110 transition ease-in-out duration-300"
            />
          </div>
        </div>

        <div className={"flex 2xs:flex-row flex-col"}>
          {/*<a href={"#contact"} className={"bg-accent font-black text-xl xs:text-2xl px-10 py-5 my-16"}>*/}
          {/*    Talk to a Ninja*/}
          {/*</a>*/}
          {/*<a href={"#contact"}  className={"underline block text-link ml-10 text-xl xs:text-2xl place-self-center"}>Contact Us</a>*/}
        </div>
      </section>

      <section className={"px-6 py-8 md:py-16 bg-gray-900"}>
        <div className={"max-container"}>
          <div className={"md:flex relative justify-center w-full"}>
            <div className={"flex md:w-1/2 lg:w-1/3"}>
              <StaticImage
                src={"../images/yachting-ai-yacht-browser-2.png"}
                alt={"Yachting.AI Tailored AI Yacht Browser"}
                className="hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300"
              />
            </div>
            <div className={"md:w-1/2 md:ml-20 flex flex-col justify-center "}>
              <h2
                className={"text-var-6xl block font-bold md:mb-12 lg:w-full gradient-text-fire"}
              >
                AI Yacht Browser
              </h2>
              <p
                className={"text-var-2xl my-4 col-start-1 row-start-2 lg:w-full text-gray-300"}
              >
                <Sentence>
                  A fresh and highly engaging approach to mobile browsing and
                  searching for yachts.
                  <br />
                  <br />
                </Sentence>
                <Sentence>
                  It combines simplicity, intuitiveness, and an X factor that
                  turns mobile searching from an irritating task into an
                  enjoyable and engaging experience.
                  <br />
                  <br />
                </Sentence>
              </p>
              <button
                className={"relative text-center gradient-bg-fire hover:gradient-bg-purple text-black hover:text-white uppercase font-bold text-xl rounded-full ml-0 py-4 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
                onClick={() => {
                  setProduct(Product.AI_YACHT_BROWSER);
                  setShowModal(true);
                }}
              >
                Get More Info
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className={"px-6 py-8 max-container"}>
        <div className={"md:flex relative w-full"}>
          <div className={"flex flex-col justify-center md:w-1/2"}>
            <h2
              className={"text-var-6xl block md:mb-12 w-full lg:w-full md:w-auto"}
            >
              AI Yacht Search
            </h2>
            <p
              className={"text-var-2xl my-4 col-start-1 row-start-2 w-full md:w-auto lg:w-full"}
            >
              <Sentence>
                Understand the user’s intent with the power of Natural Language
                Understanding (NLU)
              </Sentence>
            </p>
            <button
              className={"relative inline-block text-center gradient-bg-purple hover:gradient-bg-fire text-white hover:text-black uppercase font-bold text-xl rounded-full ml-0 md:w-auto w-full py-4 mt-8 md:my-8 px-8 hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300 lg:w-1/2"}
              onClick={() => {
                setProduct(Product.AI_YACHT_SEARCH);
                setShowModal(true);
              }}
            >
              Request Early Access
            </button>
          </div>
          <div
            className={"w-1/2 ml-10 flex flex-col justify-center hidden md:inline-block"}
          >
            <StaticImage
              src={"../images/yachting-ai-search.png"}
              alt={"AI Yacht Search - Yachting AI - WhiteCube.AI"}
            />
          </div>
        </div>
      </section>


    <section className={"px-6 py-8 md:py-16 bg-gray-900"}>
        <div className={"max-container"}>

          <div>
            <h2
              className={"text-var-6xl block font-bold leading-normal text-center md:mb-12 lg:w-full"}
            >Yachting Technology News</h2>
          </div>

          <div className={"md:flex relative justify-center w-full"}>
            
            <div 
              className="grid md:grid-cols-3 grid-cols-1 mt-10 gap-8" 
            > 

              {blog?.posts?.map(post => (

                <Link key={post.slug} className="bg-white transition-transform duration-700 hover:-translate-y-3" to={"/blog/" + post.slug + '/'}>

                  <BlogPostHeroImage blog={post} className={"w-full"} />

                   <div className="m-5"> 
                      <p>{post.meta.date}</p> 
                      <h3 className="py-2 text-xl font-bold">{post.tableOfContents?.items[0]?.title || post.meta.title}</h3> 
                      <p>{post.excerpt}</p>
                    </div> 
                </Link>

              ))}
               
                </div>



          </div>
        </div>
      </section>

      {/*<section className={"px-6 py-16 bg-alt-background h-screen md:h-auto"}>*/}
      {/*    <div className={"max-container"}>*/}
      {/*        <h2 className={"text-var-7xl text-left lg:text-center font-black mb-12 "} id={"what"}>What we&nbsp;do</h2>*/}
      {/*        <h3 className={"text-var-4xl font-black text-left lg:text-center"}>We create <Accent>high-performance</Accent>, <Accent>tightly*/}
      {/*            secured</Accent>,<br className={"hidden xl:block"} /> <Together>and <Accent>SEO-optimized</Accent></Together> <Together>Jamstack websites</Together>.</h3>*/}
      {/*        <p className={"xs:text-3xl 2xs:text-2xl text-xl pt-20 text-left lg:text-center"}>*/}
      {/*            Our team thoughtfully 🧠 listens to Google developers <br className={"hidden lg:block"} />*/}
      {/*            to deliver websites that best correspond with search <br className={"hidden lg:block"} />*/}
      {/*            engine signals as <Accent2>Core Web Vitals</Accent2> 🚀.</p>*/}
      {/*    </div>*/}
      {/*</section>*/}

      {/*<section className={"w-full h-full aspect-video"}>*/}
      {/*    <YouTube*/}
      {/*         src={"https://www.youtube.com/embed/S_tvd4bylPk"}*/}
      {/*         title={"Welcome to Gatsby Ninja"}*/}
      {/*         className={"w-full h-full aspect-video"} />*/}
      {/*</section>*/}

      {/* <div class="fixed inset-0 flex items-center justify-center z-30"> */}
      {/*   <div class="absolute inset-0 bg-black opacity-80"></div> */}
      {/*   <div className="p-6 max-w-lg mx-auto font-sans antialiased z-40"> */}
      {/*     <div className="rounded-3xl shadow-2xl bg-white"> */}
      {/*       <div className="p-8 text-center sm:p-12 relative"> */}
      {/**/}
      {/*         <div class="absolute top-2 right-2"> */}
      {/*           <button type="button" class="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800" data-hs-overlay="#hs-cookies"> */}
      {/*             <span class="sr-only">Close</span> */}
      {/*             <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      {/*               <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"></path> */}
      {/*             </svg> */}
      {/*           </button> */}
      {/*         </div> */}
      {/**/}
      {/*         <p className="text-sm font-semibold uppercase text-pink-500"> */}
      {/*           AI Yacht Search */}
      {/*         </p> */}
      {/**/}
      {/*         <h2 className="m-6 uppercase text-3xl font-bold ">Request a Demo</h2> */}
      {/**/}
      {/*           <form action="" class="space-y-4"> */}
      {/*             <div> */}
      {/*               <label class="sr-only" for="name">Name</label> */}
      {/*               <input class="w-full border rounded-lg border-solid border-gray-200 p-3 text-sm" placeholder="Name" type="text" id="name" /> */}
      {/*             </div> */}
      {/**/}
      {/*             <div class="grid grid-cols-1 gap-4 sm:grid-cols-2"> */}
      {/*               <div> */}
      {/*                 <label class="sr-only" for="email">Email</label> */}
      {/*                 <input class="w-full rounded-lg border border-gray-200 p-3 text-sm" placeholder="Email address" type="email" id="email" /> */}
      {/*               </div> */}
      {/**/}
      {/*               <div> */}
      {/*                 <label class="sr-only" for="phone">Phone</label> */}
      {/*                 <input class="w-full rounded-lg border border-gray-200 p-3 text-sm" placeholder="Phone Number" type="tel" id="phone" /> */}
      {/*               </div> */}
      {/*             </div> */}
      {/**/}
      {/*             <div> */}
      {/*               <label class="sr-only" for="message">Message</label> */}
      {/*               <textarea class="w-full rounded-lg border border-gray-200 p-3 text-sm" placeholder="Message" rows="5" id="message"></textarea> */}
      {/*             </div> */}
      {/*           </form> */}
      {/**/}
      {/*         <a className="text-xl mt-8 inline-block w-full rounded-full bg-pink-600 py-4 font-bold text-white shadow-xl" href=""> */}
      {/*           Submit */}
      {/*         </a> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </div> */}

      <RequestDemo
        // product={"AI Yacht ChatBot"}
        product={product}
        onClose={() => setShowModal(false)}
        show={showModal}
      />

      <section className={"px-6 py-16 bg-gray-900"}>
        <div className={"max-container"}>
          <h2
            className={"text-var-6xl lg:pb-14 text-left lg:text-center font-bold gradient-text-fire"}
          >
            Contact Us
          </h2>
          <ContactForm />
        </div>
      </section>

      {/* <TrustedBy /> */}
    </Layout>
  );
};

export default IndexPage;
